import React, { CSSProperties, useState } from "react"
import cx from "classnames"
import styles from "./review-text-input.module.scss"
import TextareaAutosize from "react-textarea-autosize"
import { Progress } from "./progress"
import { analytics } from "entity/analytics"

export interface IReviewTextInput {
  label?: string
  description?: React.ReactNode
  value?: string
  onChange: (value: string) => void
  className?: string
  style?: CSSProperties
  htmlId?: string
  analytics_id?: string
}

export const ReviewTextInput = (props: IReviewTextInput) => {
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const handleFocus = () => {
    if (props.analytics_id) analytics.send(props.analytics_id + ".focus")
    setIsFocused(true)
  }
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (props.analytics_id) analytics.send(props.analytics_id + ".type", { value: e.target.value })
    props.onChange(e.target.value)
  }
  const handleBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (props.analytics_id) analytics.send(props.analytics_id + ".blur", { value: e.target.value })
    setIsFocused(false)

    if (props.value?.trim() !== props.value && props.value) {
      props.onChange(props.value.trim())
    }
  }

  const length = props.value?.trim().length

  return (
    <div className={cx(styles.review_text_input, props.className)} style={props.style} id={props.htmlId}>
      <label className={styles.input_container}>
        <span className={cx(styles.label, (isFocused || props.value?.trim()) && styles.focus)}>{props.label}</span>
        <TextareaAutosize
          className={styles.input}
          value={props.value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </label>

      {!length && props.description && <div className={styles.description}>{props.description}</div>}

      {!!length && <Progress value={length} />}
    </div>
  )
}
