"use client"

import { ButtonHTMLAttributes, forwardRef, useState } from "react"
import cx from "classnames"
import styles from "./button.module.scss"

export type IButton = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type"> & {
  type?: "white" | "black" | "accent" | "white_accent" | "pink"
  size?: "medium" | "large"
  fullWidth?: boolean
  children?: React.ReactNode
  htmlType?: ButtonHTMLAttributes<HTMLButtonElement>["type"]
  className?: string
  loading?: boolean
}

export const Button = forwardRef<HTMLButtonElement, IButton>(
  ({ type = "white", size = "medium", htmlType, className, fullWidth, ...props }, ref) => {
    const [loading, setLoading] = useState(false)

    const onClick: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> = async (e) => {
      if (loading || props.loading || props.disabled) return
      e.preventDefault()
      try {
        setLoading(true)
        await props.onClick?.(e as any)
      } finally {
        setLoading(false)
      }
    }

    return (
      <button
        className={cx(styles.button, styles[type], styles[size], fullWidth && styles.full_width, className)}
        type={htmlType}
        ref={ref}
        {...props}
        onClick={props.onClick && onClick}
      >
        {props.children}
        {(loading || props.loading) && <span className={styles.loader} />}
      </button>
    )
  },
)
