import Image from "next/image"
import cx from "classnames"
import styles from "../review-form.module.scss"
import CheckIcon from "./check.svg"
import { IconX } from "@tabler/icons-react"
import { ReviewRequest } from "features/review/review-request"
import { OrderDetailsDto } from "shared/api"
import { analytics } from "entity/analytics"
import { useEffect, useRef } from "react"
import { useInView } from "shared/lib/use-in-view"
import { formatMoney, KeyName, useKeyDown } from "shared/lib"

interface ISuccessWithOrdersReviewModal {
  onClose: () => void
  mutate?: () => void
  onClickReviewInput: () => void
  className?: string
  style?: React.CSSProperties
  htmlId?: string
  orders: Array<OrderDetailsDto>
  pointsForReview: number
  showPointsForReview?: boolean
}

export const SuccessWithOrdersReviewModal = (props: ISuccessWithOrdersReviewModal) => {
  const ref = useRef<HTMLDivElement>(null)
  const inView = useInView(ref)

  useEffect(() => {
    if (inView) {
      analytics.send("review_form.success_with_orders_review_modal.view")
    }
  }, [inView])

  const onClickClose = () => {
    analytics.send("review_form.success_with_orders_review_modal.close_button.click")
    props.onClose()
  }

  useKeyDown(KeyName.ESC, onClickClose)

  return (
    <div className={cx(styles.review_form, styles.success_with_orders)} ref={ref}>
      <div className={styles.head}>
        <div className={styles.title_row}>
          <CheckIcon className={styles.icon} />
          <div className={styles.title}>Спасибо за оценку!</div>
          <div className={styles.close} onClick={onClickClose}>
            <IconX stroke={2} />
          </div>
        </div>
        <div>
          <div className={styles.description}>Вы очень помогаете другим покупателям</div>
          {props.showPointsForReview && !!props.pointsForReview && (
            <div className={styles.description}>
              Начислим <span className={styles.points_text}>{formatMoney(props.pointsForReview)}</span>, когда проверим
              отзыв.
            </div>
          )}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>Поделитесь, как вам покупки</div>
        <div className={styles.orders}>
          {props.orders.map((item) => (
            <div className={styles.order} key={item.id}>
              <div className={styles.image_wrapper}>
                <div className={styles.image}>
                  <div className={styles.image_inner}>
                    <Image
                      src={item.productPoizonImageUrls?.[0] || item.productImages[0]?.url}
                      alt=""
                      fill
                      sizes="100%"
                      unoptimized
                    />
                  </div>
                </div>
              </div>
              <div className={styles.order_content}>
                <div className={styles.order_content_header}>
                  <div className={styles.title}>Как вам товар?</div>
                  {!!(item.pointsForReviewPhotos || item.pointsForReviewText) && (
                    <div className={styles.points_text}>
                      +{formatMoney(item.pointsForReviewPhotos + item.pointsForReviewText)}
                    </div>
                  )}
                </div>
                <ReviewRequest
                  orderId={item.id}
                  starSize={32}
                  className={styles.review_input}
                  mutate={props.mutate}
                  onClick={props.onClickReviewInput}
                  analyticsParamType="review_form.success_with_orders_review_modal"
                  starClassName={styles.review_input_star}
                  pointsForPhoto={item.pointsForReviewPhotos}
                  pointsForText={item.pointsForReviewText}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
