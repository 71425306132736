import React, { CSSProperties, useEffect, useRef } from "react"
import cx from "classnames"
import { StarsInput } from "../review-form/components/stars-input"
import { ReviewModal } from "features/review/review-modal"
import styles from "./review-request.module.scss"
import { useInView } from "shared/lib/use-in-view"
import { analytics } from "entity/analytics"

export interface IReviewRequest {
  orderId: string
  starSize?: number
  mutate?: () => void
  className?: string
  style?: CSSProperties
  htmlId?: string
  analyticsParamType: string
  onClick?: () => void
  starClassName?: string
  score?: number
  pointsForText: number
  pointsForPhoto: number
}

export const ReviewRequest = (props: IReviewRequest) => {
  const ref = useRef<HTMLDivElement>(null)

  const inView = useInView(ref)

  useEffect(() => {
    if (inView) {
      analytics.send("review_request.view", {
        type: props.analyticsParamType,
        pointsForText: props.pointsForText,
        pointsForPhoto: props.pointsForPhoto,
      })
    }
  }, [inView])

  return (
    <div className={cx(styles.review_request, props.className)} style={props.style} id={props.htmlId} ref={ref}>
      <StarsInput
        className={props.starClassName}
        onChange={(value) => {
          analytics.send("review_request.click", {
            type: props.analyticsParamType,
            score: value,
          })
          ReviewModal.open({ orderId: props.orderId, initScore: value, mutate: props.mutate })
          props.onClick?.()
        }}
        value={props.score}
        size={props.starSize}
      />
    </div>
  )
}
