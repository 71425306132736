"use client"
import { useEffect } from "react"

export const useDisableBodyScroll = (open: boolean) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"

      return () => {
        document.body.style.removeProperty("overflow")
      }
    }
  }, [open])
}
