"use client"

/* eslint-disable */

import React, { useEffect, useState } from "react"

export type UseModalProps = {
  isOpen?: boolean
  close: () => void
  onSuccess: () => void
}

interface IModalState {
  modal: any
  props: any
}

let state: IModalState[] = []
let updaters: ((value: IModalState[]) => void)[] = []

type OptionalIfEmpty<T> = {} extends T ? [{}?] : [T]
type OmitType<T1, T2> = Pick<T1, Exclude<keyof T1, keyof T2>>

const useCurrentModal = () => {
  const [value, update] = useState<IModalState[]>(state)

  useEffect(() => {
    updaters.push(update)
    return () => {
      updaters = updaters.filter((it) => it !== update)
    }
  }, [])

  return value
}

const setState = (fn: (currentState: IModalState[]) => IModalState[]) => {
  state = fn(state)
  updaters.forEach((it) => it(state))
}

export class Modals {
  static open<T>(
    modal: React.ComponentType<T>,
    props: OmitType<T, UseModalProps> & {
      onSuccess?: UseModalProps["onSuccess"]
      onClose?: () => void
    },
  ) {
    setState((state) => [...state, { modal, props }])
  }

  static close(modal?: React.ComponentType<any>) {
    setState((state) => state.filter((it) => it.modal !== modal))
  }
}

export const ModalsRoot = () => {
  const state = useCurrentModal()
  if (!state.length) return null

  return (
    <>
      {state.map((it, key) => (
        <it.modal
          {...it.props}
          key={key}
          close={() => {
            try {
              it.props?.onClose?.()
            } catch (ignore) {}
            Modals.close(it.modal)
          }}
          onSuccess={async () => {
            await it.props.onSuccess?.()
            Modals.close(it.modal)
          }}
        />
      ))}
    </>
  )
}
