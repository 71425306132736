import React, { CSSProperties } from "react"
import cx from "classnames"
import styles from "./checkout-header.module.scss"

export interface ICheckoutHeader {
  children?: React.ReactNode
  className?: string
  style?: CSSProperties
  htmlId?: string
}

export const CheckoutHeader = (props: ICheckoutHeader) => {
  return (
    <div className={cx(styles.checkout_header, props.className)} style={props.style} id={props.htmlId}>
      <div className={styles.text}>{props.children}</div>
    </div>
  )
}
