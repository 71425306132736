import React, { useEffect, useRef } from "react"
import cx from "classnames"
import styles from "./review-modal.module.scss"
import { Modals, SessionStorage, useDisableBodyScroll, UseModalProps } from "shared/lib"
import { CustomerOrderReviewDto } from "shared/api"
import { analytics } from "entity/analytics"
import { ReviewForm } from "../review-form"

export interface IReviewModal extends UseModalProps {
  orderId: string
  review?: CustomerOrderReviewDto
  initScore?: number
  mutate?: () => void
}

export const ReviewModal = (props: IReviewModal) => {
  const firstRender = useRef(true)

  useDisableBodyScroll(true)

  useEffect(() => {
    analytics.send("review_modal.open", {
      mode: props.review ? "update" : "create",
      orderId: props.orderId,
      hasReview: !!props.review,
      initScore: props.initScore,
    })
    return () => analytics.send("review_modal.close")
  }, [])

  useEffect(() => {
    SessionStorage.setItem("hide_order_review_popup", "true")
  }, [])

  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      if (firstRender.current) {
        firstRender.current = false
        return
      }
    }

    const scrollTop = document.documentElement.scrollTop
    const maxScrollTop = document.documentElement.scrollHeight - document.documentElement.clientHeight

    document.documentElement.scrollTo({ top: 0, behavior: "instant" })

    document.body.style.position = `fixed`
    document.body.style.width = `100%`
    document.body.style.top = `-${scrollTop}px`
    document.body.style.overflow = "hidden"

    if (scrollTop > maxScrollTop) {
      requestAnimationFrame(() => {
        document.body.style.transition = "top 0.4s"
        document.body.style.top = `-${maxScrollTop}px`
      })
    }

    return () => {
      document.body.style.removeProperty("transition")
      document.body.style.removeProperty("position")
      document.body.style.removeProperty("width")
      document.body.style.removeProperty("top")
      document.documentElement.scrollTo({ top: scrollTop, behavior: "instant" })
      document.body.style.removeProperty("overflow")
    }
  }, [])

  return (
    <div className={cx(styles.review_modal)}>
      <div className={styles.review_modal_overlay} onClick={props.close} />
      <div className={styles.review_modal_inner}>
        <ReviewForm
          orderId={props.orderId}
          initScore={props.initScore}
          onClose={() => setTimeout(props.close)}
          mutate={props.mutate}
        />
      </div>
    </div>
  )
}

ReviewModal.open = (props: Omit<IReviewModal, keyof UseModalProps>) => Modals.open(ReviewModal, props)
