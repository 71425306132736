"use client"

import React, { CSSProperties } from "react"
import cx from "classnames"
import styles from "./review-product.module.scss"
import { OrderDetailsDto, poizonImageLoaderPropsIfNeed } from "shared/api"
import Image from "next/image"
import { analytics } from "entity/analytics"

export interface IReviewProduct {
  order: OrderDetailsDto

  className?: string
  style?: CSSProperties
  htmlId?: string
}

export const ReviewProduct = (props: IReviewProduct) => {
  const name = props.order.productModel
  const image = props.order.productImages[0]?.url || props.order.productPoizonImageUrls?.[0]!

  return (
    <div className={cx(styles.review_product, props.className)} style={props.style} id={props.htmlId}>
      <div className={styles.image} onClick={() => analytics.send("review_form.product.image.click")}>
        <div className={styles.inner}>
          <Image src={image} width={0} height={0} sizes="50%" alt="" {...poizonImageLoaderPropsIfNeed(image, true)} />
        </div>
      </div>

      <div className={styles.body}>
        <div
          className={cx(styles.name, name?.length! > 30 && styles.small)}
          onClick={() => analytics.send("review_form.product.name.click")}
        >
          {name}
        </div>
      </div>
    </div>
  )
}
