import { IconX } from "@tabler/icons-react"
import styles from "../review-form.module.scss"
import cx from "classnames"
import { CSSProperties } from "react"
import Image from "next/image"
import heartImage from "./heart.png"
import { Button } from "shared/ui"

interface ISuccessReviewModal {
  className?: string
  style?: CSSProperties
  htmlId?: string
  onClose: () => void
}

export const SuccessReviewModal = (props: ISuccessReviewModal) => {
  return (
    <div className={cx(styles.review_form, styles.success, props.className)} style={props.style} id={props.htmlId}>
      <div className={styles.head}>
        <div className={styles.close} onClick={props.onClose}>
          <IconX stroke={2} />
        </div>
      </div>
      <div className={styles.success_container}>
        <Image src={heartImage} alt="" width={80} height={80} className={styles.image} />
        <div className={styles.title}>Спасибо за отзыв!</div>
        <div className={styles.description}>
          Опубликуем его после проверки. Оставив отзыв, вы очень помогаете другим покупателям.
        </div>

        <div className={styles.success_actions}>
          <Button type="accent" className={styles.button} onClick={props.onClose}>
            Закрыть
          </Button>
        </div>
      </div>
    </div>
  )
}
